enum HttpHeader {
  AUTHORIZATION = "authorization",
  CONTENT_TYPE = "content-type",
  USER_AGENT = "user-agent",
  X_UTILS_SUBJECT = "x-utils-subject",
  X_FORWARDED_PROTO = "x-forwarded-proto",
  X_FORWARDED_HOST = "x-forwarded-host",
  X_CANARY = "x-canary",
  X_PARROT = "x-parrot",
  X_PHP_NEXT_VERSION = "x-php-next-version",
  X_STORE_ID = "x-store-id",
  X_USER_ID = "x-user-id",
  X_TIENDANUBE_USER_ID = "x-tiendanube-user-id",
  X_PLAN_LOCATION_ID = "x-plan-location-id",
}

export default HttpHeader;
